<script>
    export default {
        name: 'CcsTermsDutchView',
    };
</script>

<template>
    <div>
        <h3 class='cb-service-main-title'>
            Beschrijving van de dienst
        </h3>

        <p>Dankzij de CARO dienst kan je de professionele kredietkaartafschriften van je klanten rechtstreeks aangeleverd krijgen wanneer de titularis en de kaarthouder hun machtiging hiertoe verleend hebben. De CARO-bestanden zullen maandelijks aangeleverd worden, in lijn met de boekingsperiode van de kredietkaartuitgever, indien er minimum 1 betaling met de kredietkaart gebeurd is gedurende de boekingsperiode van de kredietkaartuitgever. Indien er geen betalingen gebeurd zijn gedurende de boekingsperiode, zal de kredietkaartuitgever geen kredietkaartafschrift opmaken en zal CodaBox dus ook geen afschriften ontvangen of kunnen afleveren.</p>

        <p>De CodaBox CARO-bestanden zullen aangeleverd worden via de bestaande beveiligde CODA afleverkanalen.</p>

        <p>De CodaBox CARO-bestanden :</p>

        <ul>
            <li>
                Betreffen Mastercard en VISA kredietkaarten uitgegeven door volgende banken:
                <ul>
                    <li>
                        <a href='https://www.codabox.com/nl/caro/' target='_blank'>https://www.codabox.com/nl/caro/</a>
                    </li>
                </ul>
            </li>
            <li>Krijgen een logische naam</li>
            <li>Worden in een PDF-bestand geleverd en in een gestructureerd formaat</li>
        </ul>

        <p>Het gebruik van de CARO dienst biedt jou en je klanten veel voordelen zoals meer efficiëntie, minder fouten en minder ontbrekende documenten. Je hebt zo de zekerheid dat je alle relevante kredietkaartafschriften (van je klant) ontvangt en je hoeft geen handmatige invoer meer te doen.</p>

        <p>CodaBox lanceerde de CARO dienst met een beperkt aantal Belgische banken. Het aantal ondersteunde banken wordt gaandeweg uitgebreid. De lijst van banken voor wiens kredietkaarten de CARO dienst wordt aangeboden, kan je vinden op <a href='https://www.codabox.com/nl/caro/' target='_blank'>https://www.codabox.com/nl/caro/</a></p>

        <p>De CARO dienst van CodaBox is niet afzonderlijk beschikbaar. CARO-bestanden kunnen enkel worden aangeleverd voor de professionele klanten voor wie CodaBox eveneens CODA aanlevert op basis van een daartoe afgeleverd mandaat.</p>

        <h3>Tarieven</h3>

        <h4 class='cb-service-title'>
            Administratieve kosten
        </h4>

        <p>24 € per klantenreferentie(*)</p>

        <h4 class='cb-service-title'>
            Recurrente kosten
        </h4>

        <table class='table'>
            <tbody>
                <tr>
                    <td>+ 500 klantenreferenties</td>
                    <td>3,62 €/maand/klantenreferentie</td>
                </tr>
                <tr>
                    <td>Tussen 241 en 500 klantenreferenties</td>
                    <td>4,53 €/maand/klantenreferentie</td>
                </tr>
                <tr>
                    <td>Tussen 121 en 240 klantenreferenties</td>
                    <td>5,01 €/maand/klantenreferentie</td>
                </tr>
                <tr>
                    <td>Tussen 61 en 120 klantenreferenties</td>
                    <td>6,28 €/maand/klantenreferentie</td>
                </tr>
                <tr>
                    <td>Tussen 3 en 60 klantenreferenties</td>
                    <td>7,53 €/maand/klantenreferentie</td>
                </tr>
                <tr>
                    <td>Voor 1 en 2 klantenreferenties</td>
                    <td>9,37 €/maand/klantenreferentie</td>
                </tr>
            </tbody>
        </table>

        <p>Voor de bepaling van het correcte tarief wordt ook rekening gehouden met het aantal geactiveerde diensten voor CODA en VOILA. Meer details kan je vinden in de Algemene Voorwaarden, via de link hieronder</p>

        <p class='note'>
            <span class='fa fa-info-circle'></span>
            CodaBox kan niet bepalen wanneer de dienst CODA, CARO of VOILA niet meer nodig is voor een bepaalde klant, kredietkaart of bankrekening,
            bijvoorbeeld wanneer een rekening gesloten wordt of er geen transacties meer op gebeuren. De dienst blijft actief
            (en blijft deel uitmaken van de berekening van de recurrente kosten) tot je deze zelf
            <a href='https://faq.codabox.com/nl/support/solutions/articles/75000087558-hoe-een-dienst-coda-soda-voila-caro-deactiveren-' target='_blank'>via MyCodaBox deactiveert</a>.
        </p>

        <p>(*)identificatie van de kredietkaart-uitgavenstaat, sommige bankproducten ondersteunen meerdere kaarten op 1 kredietkaart-uitgavenstaat (dwz. Klantenreferentie).</p>

        <h3>Algemene voorwaarden</h3>

        <p>
            <a href='/static/pdfs/MyCodaBox.com 202401 CB-Fidu NL-Annex CARO (B4-C4-D).pdf' target='_blank'>Algemene voorwaarden van de dienst</a>
        </p>
    </div>
</template>

<style>
    h3.cb-service-main-title {
        margin-top: 10px;
    }
    h4.cb-service-title {
        text-decoration: underline;
    }
    .cb-service-info-highlight {
        font-style: italic;
        font-weight: bold;
    }
</style>
