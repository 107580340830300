<script>
    import download from 'downloadjs';
    import notify from '../notify.js';
    import { authFetch as fetch } from '../api/fetch.js';
    import Tooltip from '@/components/Tooltip';
    import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';

    export default {
        name: 'CodaDownload',
        props: {
            mandate: {
                type: Object,
                required: true,
            },
        },
        components: {
            Tooltip,
            FriendlyButton,
        },
        methods: {
            async doDownload () {
                try {
                    let res = await fetch(
                        `bff/api/downloads/coda-mandate?mandateId=${this.mandate.id}`,
                        {
                            method: 'GET',
                            headers: {
                                'Accept': 'application/pdf',
                            },
                        },
                    );
                    if (res.status !== 200) {
                        throw Error(res.statusText);
                    }
                    download(await res.blob(), 'coda_mandate.pdf');
                } catch {
                    notify.error(this.$t('err-unknown'));
                }
            },
        },
    };
</script>
<template>
    <div class='flex'>
        <!-- If a bank has disabled pdf mandate, it has to have Twikey or Nitro active.
        This could be updated in the future if there is a signhereSupported field -->
        <Tooltip v-if='mandate.bank.disableSendPdfMandate' left>
            <template #trigger>
                <FriendlyButton
                    label='btn-download-complete'
                    square
                    extra-small
                    micro
                    no-margin
                    :disabled='mandate.bank.disableSendPdfMandate'
                />
            </template>
            <template #content>
                <div v-if='mandate.bank.isTwikeySupported'>
                    {{ $t('p-pdf-no-download-twikey') }}
                </div>
                <div v-else-if='!mandate.bank.isTwikeySupported'>
                    {{ $t('p-pdf-no-download-digitally-signed') }}
                </div>
            </template>
        </Tooltip>
        <div v-else>
            <FriendlyButton
                label='btn-download-complete'
                square
                :action='() => { doDownload() }'
                extra-small
                micro
                no-margin
            />
        </div>
    </div>
</template>
