<script>
    import ListView from '@/components/ListView.js';
    import Exporter from '@/components/Exporter.vue';
    import ClientLink from '@/components/ClientLink.vue';
    import VerifiedEmail from '@/components/VerifiedEmail.vue';
    import ExactEmail from '@/components/ExactEmail.vue';
    import EnterpriseNumber from '@/components/EnterpriseNumber.vue';
    import ContentBox from '@/components/ContentBox.vue';
    import CustomTitle from '@/components/Title';
    import { dateFormat } from '@/filters';
    import SearchInput from '@/components/SearchInput';
    import Dropdown from '@/components/DropdownV2';
    import DropdownItem from '@/components/DropdownItem';
    import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';

    export default {
        name: 'ClientSearch',
        mixins: [ListView],
        props: {
            accountant: {
                type: Object,
            },
            currentEnvironment: {
                type: Object,
            },
        },
        components: {
            'exporter':       Exporter,
            'clientlink':     ClientLink,
            VerifiedEmail,
            'exact-email':  ExactEmail,
            'enterprise-num': EnterpriseNumber,
            ContentBox,
            CustomTitle,
            SearchInput,
            Dropdown,
            DropdownItem,
            FriendlyButton,
        },
        data () {
            var data = this.listViewData({
                apiModel: 'bff/api/search/clients',
                defaultSortKey: 'client_code',
                defaultFilters: {'state': 'active'},
            });

            this.configureSelectFilter(data, 'exactOnline', [
                {
                    value: 'allclients',
                    label: 'lbl-exact-email',
                    filter: null,
                },
                {
                    value: 'withexactemail',
                    label: 'opt-with-exact-email',
                    filter: {'has_exact_email': 'True'},
                }, {
                    value: 'withoutexactemail',
                    label: 'opt-without-exact-email',
                    filter: {'has_exact_email': 'False'},
                },
            ]);

            this.configureSelectFilter(data, 'contactemails', [
                {
                    value: 'allclients',
                    label: 'opt-contact-emails',
                    filter: null,
                },
                {
                    value: 'withemails',
                    label: 'opt-with-contact-email',
                    filter: {'has_contact_email': 'True'},
                },
                {
                    value: 'noemails',
                    label: 'opt-without-contact-email',
                    filter: {'has_contact_email': 'False'},
                },
            ]);

            return data;
        },
        computed: {
            isFiduExactOnline () {
                return this.currentEnvironment.isExactOnline;
            },
            hasCodaOnlyContract () {
                return this.accountant.hasCodaOnlyContract;
            },
            isDirectCustomer () {
                return this.accountant.isDirectCustomer;
            },
        },
        watch: {
            'currentEnvironment.id': function () {
                this.search();
            },
        },
        mounted () {
            this.watchFilters();
            this.setupPathFilters();
            this.search();
        },
        methods: {
            dateFormat: dateFormat,
            dynamicSearchParams () {
                return { 'fiduciaryId': this.currentEnvironment.id };
            },
            setupPathFilters () {
                if (this.$route.name === 'fidu-client-search-no-purchases') {
                    this.setSelectFilterValue('contactemails', 'noemails');
                }
            },
            hasSubscribedVoilaService (record) {
                const state = record.purchase_invoice_service_state;
                return state === 'ordered' || state === 'active';
            },
        },
    };
</script>

<template>
    <div>
        <div class='cb-fidu-title'>
            <div class='cb-fidu-actions'>
                <exporter model='companies' :label='$t("btn-export-clients")' :fiduciary-id='currentEnvironment.id' />
            </div>
            <CustomTitle class='mt-6 mb-6'>
                {{ $t('h-clients') }}
            </CustomTitle>
        </div>

        <ContentBox>
            <div class='flex'>
                <SearchInput v-model='list.textsearch' class='mr-3' />

                <Dropdown
                    v-model='list.filters.exactOnline.selected'
                    class='mr-3'
                    default-value='allstates'
                    direction='right'
                    v-if='isFiduExactOnline'
                >
                    <DropdownItem
                        v-for='option in list.filters.exactOnline.options'
                        :id='option.value'
                        :name='$t(option.label)'
                        :key='option.value'
                        class='whitespace-nowrap'
                    >
                        {{ $t(option.label) }}
                    </DropdownItem>
                </Dropdown>

                <Dropdown
                    v-model='list.filters.contactemails.selected'
                    default-value='allstates'
                    direction='right'
                >
                    <DropdownItem
                        v-for='option in list.filters.contactemails.options'
                        :id='option.value'
                        :name='$t(option.label)'
                        :key='option.value'
                        class='whitespace-nowrap'
                    >
                        {{ $t(option.label) }}
                    </DropdownItem>
                </Dropdown>
            </div>
            <pagination
                :list='list'
                :pagination='pagination'
                :ctrls='getControls()'
                size='small'
                class='my-6'
            />

            <div class='cb-bordered' :class='{"table-responsive": $store.state.gui === "mobile"}'>
                <table class='table cb-searchresults'>
                    <colgroup>
                        <collumn class='cb-col-lg' :sorting='sorting' skey='client_code' />
                        <collumn class='cb-col-md' :sorting='sorting' skey='name' />
                        <collumn class='cb-col-md' :sorting='sorting' skey='enterprise_num' />
                        <collumn class='cb-col-md' :sorting='sorting' skey='contact_email' />
                        <collumn class='cb-col-md' v-if='isFiduExactOnline' :sorting='sorting' skey='exact_email' />
                        <collumn class='cb-col-md' :sorting='sorting' skey='state_modified_at' />
                        <col class='cb-col-xl'>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>
                                <span>{{ $t('th-client-code') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='client_code' />
                            </th>
                            <th>
                                <span>{{ $t('th-client-name') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='name' />
                            </th>
                            <th>
                                <span>{{ $t('th-enterprise-number') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='enterprise_num' />
                            </th>
                            <th>
                                <span>{{ $t('th-client-email') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='contact_email' />
                            </th>
                            <th v-if='isFiduExactOnline'>
                                <span>{{ $t('lbl-exact-email') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='exact_email' />
                            </th>
                            <th>
                                <span>{{ $t('th-creation-date') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='state_modified_at' />
                            </th>
                            <th>
                                <span>{{ $t('th-actions') }}</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for='record in list.records' :key='record.id'>
                            <td>
                                <clientlink :client='record' />
                            </td>
                            <td>{{ record.name }}</td>
                            <td>
                                <enterprise-num :enterprise-number='record.enterprise_num' />
                            </td>
                            <td class='max-w-xs'>
                                <VerifiedEmail :email='record.contact_email' :verification-state='record.contact_email_verification_state' />
                            </td>
                            <td v-if='isFiduExactOnline' class='max-w-xs'>
                                <exact-email :email='record.exact_email' />
                            </td>
                            <td>{{ dateFormat(record.state_modified_at) }}</td>
                            <td>
                                <FriendlyButton
                                    label='btn-new-coda-mandate'
                                    class='mb-2 mr-2'
                                    :action='() => {
                                        $router.push({
                                            name:"fidu-client-uid-coda",
                                            params:{ uid: (record.id || record.uuid)}
                                        })
                                    }'
                                    square
                                    extra-small
                                    micro
                                    no-margin
                                />
                                <template v-if='!hasCodaOnlyContract'>
                                    <FriendlyButton
                                        label='btn-new-soda-mandate'
                                        class='mb-2 mr-2'
                                        :action='() => {
                                            $router.push({
                                                name:"fidu-client-uid-soda",
                                                params:{ uid: (record.id || record.uuid)}
                                            })
                                        }'
                                        square
                                        extra-small
                                        micro
                                        no-margin
                                    />
                                    <FriendlyButton
                                        v-if='!isDirectCustomer'
                                        :label='!hasSubscribedVoilaService(record) ? "btn-subscribe-purchase-invoice-service" : "btn-manage-voila"'
                                        :action='() => {
                                            $router.push({
                                                name:"fidu-client-uid-voila",
                                                params:{ uid: (record.id || record.uuid)}
                                            })
                                        }'
                                        square
                                        extra-small
                                        micro
                                        no-margin
                                    />
                                </template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <listloader :list='list' />

            <div class='text-center' v-if='!list.loading'>
                <pagination
                    :list='list'
                    :pagination='pagination'
                    :ctrls='getControls()'
                    scroll='scrollTop'
                    no-margin
                />
            </div>
        </ContentBox>
    </div>
</template>

<style scoped>
.btn-action {
    margin-right: 2px;
}
</style>
