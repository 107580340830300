<script>
    export default {
        name: 'SodaTermsNl',
    };
</script>

<template>
    <div>
        <h3 class='cb-service-main-title'>
            Beschrijving van de dienst
        </h3>

        <p>Dankzij de SODA-dienst van CodaBox kan je automatisch, in je werkomgeving, documenten ontvangen die door de sociale secretariaten worden aangeleverd betreffende de berekening van loonfiches. Deze SODA-bestanden worden in je boekhoudsoftware geïmporteerd als diverse verrichtingen. De ontvangst en codering van boekhoudkundige documenten die je klanten op papier ontvangen van hun sociaal secretariaat kan je met deze dienst dus automatiseren. Om de SODA-bestanden te verkrijgen, moet je klant minstens een CODA-mandaat ondertekend hebben.</p>

        <p>Merk op dat de SODA-bestanden bedoeld zijn voor de boekhoudkundige uitsplitsing en dus geen facturatiegegevens bevatten tussen het sociaal secretariaat en de klant.</p>

        <p>Vanaf de ontvangst van je bestelling zal het SODA-mandaat de status ‘Niet ondertekend’ of ‘Ondertekend’ hebben naargelang de status van het gelinkte CODA-mandaat. Zodra wij je bestelling hebben doorgegeven aan het sociaal secretariaat, krijgt het mandaat de status ‘Aangevraagd’ totdat wij het eerste bestand ontvangen. Het mandaat zal vanaf dan ‘Actief’ zijn. Er kan dus een relatief lange termijn zitten tussen het moment van je bestelling en de activatie van het mandaat.</p>

        <p>
            <a href='https://www.codabox.com/diensten/soda/de-sociale-secretariaten-partners/' target='_blank'>Overzicht van de aangesloten sociale secretariaten </a>
        </p>

        <p>Indien je klanten hebt die door Securex worden beheerd, kan je de SODA-bestanden bestellen zoals voor de andere sociale secretariaten. Een vertegenwoordiger van Securex zal dan contact opnemen voor de ondertekening van het contract voor de levering van SODA-bestanden eigen aan dat sociaal secretariaat.</p>

        <p>
            <a href='https://www.codabox.com/diensten/soda/software-klaar-voor-integratie/' target='_blank'>Overzicht van de compatibele boekhoudsoftware</a>
        </p>

        <h3>Tarieven</h3>

        <h4 class='cb-service-title'>
            Administratieve kosten
        </h4>

        <p>Zodra een SODA-mandaat geactiveerd is, wordt, na de eerste ontvangst van een bestand voor dit mandaat, een eenmalig bedrag van 6,00 € per SODA-mandaat gefactureerd.</p>

        <h4 class='cb-service-title'>
            Recurrente kosten
        </h4>

        <p>Vanaf de activatie van de SODA-dienst voor jouw boekhoudkantoor wordt maandelijks een forfaitair bedrag van 67,00 € gefactureerd, ongeacht het aantal bestelde SODA-mandaten.</p>

        <h3>Algemene voorwaarden</h3>

        <p>
            <a href='/static/pdfs/MyCodaBox.com 202401 CB-Fidu NL-Annex SODA (B2-C2-D).pdf' target='_blank'>Algemene voorwaarden van de dienst</a>
        </p>
    </div>
</template>

<style>
    h3.cb-service-main-title {
        margin-top: 10px;
    }

    h4.cb-service-title {
        text-decoration: underline;
    }
</style>
