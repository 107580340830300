<script setup>

import {ref, watchEffect} from 'vue';
import { gql } from '@apollo/client/core';
import { useQuery, useQueryLoading } from '@vue/apollo-composable';
import ProductStatesChart from '@/fiduciary/modules/ProductStatesChart.vue';
import router from '@/router';

const props = defineProps({
    currentEnvironment: {
        type: Object,
        required: true,
    },
});

const products = ['CODA', 'SODA', 'VOILA', 'CARO'];
const series = ref([]);

const { refetch, onResult, onError } = useQuery(
    gql`query GetProductStates($fiduciaryId: String) {
        productStates(fiduciaryId:$fiduciaryId) {
            timestamp
            coda { active {count ids} inactive {count ids} }
            soda { active {count ids} inactive {count ids}}
            voila { active {count ids} inactive {count ids}}
            caro { active {count ids} inactive {count ids}}
        }
    }`,
    {
        fiduciaryId: props.currentEnvironment.id,
    },
);
onResult((result, context) => {
    const res = result.data.productStates;
    series.value = [
        {name: 'active', data: [res.coda.active.count, res.soda.active.count, res.voila.active.count, res.caro.active.count]},
        {name: 'inactive', data: [res.coda.inactive.count, res.soda.inactive.count, res.voila.inactive.count, res.caro.inactive.count]},
    ];
});
onError((_, context) => {
    series.value = [];
});
const loading = useQueryLoading();

watchEffect(async () => {
    refetch({
        fiduciaryId: props.currentEnvironment.id,
    });
});

function toCompanyInsights (product, status) {
    router.push({name: 'company-insights', query: {product: product, status: status}});
}
</script>

<template>
    <ProductStatesChart
        :products='products'
        :series='series'
        :loading='loading'
        @clicked='toCompanyInsights'
    />
</template>
