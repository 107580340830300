<template>
    <div class='ml-5'>
        <h1 v-if='!reactivate' class='text-2xl mb-10'>
            {{ $t('ttl-sync-activation-token-popup-activate') }} <strong>{{ podName }} </strong>
        </h1>
        <h1 v-if='reactivate' class='text-2xl mb-5'>
            {{ $t('ttl-sync-activation-token-popup-reactivate') }}  <strong>{{ podName }} </strong>
        </h1>
        <div v-if='reactivate' class='bg-amber-50 border border-amber-400 text-amber-700 px-4 py-3 rounded mb-5'>
            {{ $t('txt-sync-activation-token-popup1') }}
            <!-- ⚠️ Reactivating a connection stops the current connection. You'll be able to download files with the newly activated connection only. -->
        </div>
        <!-- Copy this Activation Token in the SyncTool to set up your connection. -->
        {{ $t('txt-sync-activation-token-popup2') }}
        <!-- Then, in the SyncTool, click "Add Connection" and paste the token in the "Activation Token" field. -->
        {{ $t('txt-sync-activation-token-popup3') }}
        <p class='mt-5 mb-6 text-center'>
            <FriendlyButton
                :action='() => copyToClipboard(token)'
                symbol='clipboard'
                extra-small
                no-margin
                square
                secondary
                fullwidth
                :disabled='loading'
            >
                <span v-if='loading' class='font-mono uuid align-middle w-auto'>generating token...</span>
                <span v-if='!loading' class='font-mono uuid align-middle w-auto'>{{ token }}</span>
            </FriendlyButton>
        </p>
        <!-- When you see "Connection Successful" in the SyncTool your files will start syncing automatically.  If you run into issues, please contact customer support. -->
        {{ $t('txt-sync-activation-token-popup4') }}
        <div class='text-center mt-5' style='padding-bottom: 0%;'>
            <FriendlyButton
                label='Close'
                :action='onDone_close'
                symbol='times'
                extra-small
                square
            />
        </div>
    </div>
</template>

<script>
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import { gql } from '@apollo/client/core';
import notify from '@/notify.js';

export default {
    props: {
        onDone: Function,
        onError: Function,
        podUuid: String,
        podName: String,
        reactivate: Boolean,
    },
    components: {
        FriendlyButton,
    },
    data () {
        return {
            loading: false,
            token: null,
        };
    },
    created () {
        this.getToken();
    },
    methods: {
        async getToken () {
            this.loading = true;
            this.token = await this.generateToken();
            this.loading = false;
        },
        async generateToken () {
            try {
                const results = await this.$apollo.query({
                    query: gql`query createSyncActivation($podUuid: String) {
                        createSyncActivation(podUuid: $podUuid) {
                            token
                        }
                    }`,
                    variables: {
                        podUuid: this.podUuid,
                    },
                });
                this.token = results.data.createSyncActivation.token;
                return this.token;
            } catch (e) {
                notify.error(this.$t('err-unknown'));
            } finally {
                this.loading = false;
            }
        },
        async copyToClipboard (token) {
            try {
                await navigator.clipboard.writeText(token);
                notify.success(this.$t('p-sync-activation-token-copied-clipboard-success'));
            } catch (err) {
                notify.error(this.$t('p-sync-activation-token-copied-clipboard-error'));
            }
        },
        onDone_close () {
            this.onDone();
        },
    },
};
</script>

<style lang="postcss" scoped>
.info-row {
    @apply mb-2;
}

.info-row:last-child {
    @apply mb-0;
}

.info-row span {
    @apply font-bold mr-2;
}
.uuid {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
