<script setup>
import { computed, watch } from 'vue';
import { useField } from 'vee-validate';
import Tooltip from '@/components/Tooltip';

const props = defineProps({
    value: {
        type: String || Boolean,
    },
    initialValue: {
        type: String || Boolean,
    },
    name: {
        type: String,
        required: true,
    },
    label: {
        type: String,
    },
    rules: {
        type: String,
        default: '',
    },
    mode: {
        type: String,
        default: 'eager',
    },
    info: String,
    disabled: {
        type: Boolean,
        default: false,
    },
    edit: {
        type: Boolean,
        default: true,
    },
});

const { value, errorMessage, handleChange } = useField(
    props.name,
    props.rules,
    {
        type: 'radio',
        label: props.label,
        initialValue: props.initialValue,
    },
);

const required = computed(() => {
    return props.rules.includes('required');
});

const id = computed(() => {
    return `${props.name}${props.value}`;
});

const emit = defineEmits(['change']);

const onRadioChange = (event) => {
    emit('change', props.value);
    handleChange(props.value);
};

watch(() => props.value, (newValue) => {
    handleChange(newValue);
});
</script>

<template>
    <div class='form-radio-wrapper' :class='{ "has-error": !!errorMessage, disabled, edit }'>
        <div class='form-radio'>
            <input
                type='radio'
                :value='value'
                @change='onRadioChange'
                :disabled='disabled || !edit'
                :id='id'
                :name='name'
            >
        </div>
        <label :for='id' class='form-radio-label'>
            <span class='truncate w-100'>
                <slot></slot><span v-if='required && edit' class='required'>*</span>
            </span>
            <span v-if='info' class='ml-2 font-normal'>
                <Tooltip>
                    <template #trigger>
                        <i class='fa fa-info-circle'></i>
                    </template>
                    <template #content>
                        <div>{{ info }}</div>
                    </template>
                </Tooltip>
            </span>
        </label>
        <p class='form-radio-errors' v-show='errorMessage'>
            {{ errorMessage }}
        </p>
    </div>
</template>

<style scoped>
.form-radio-wrapper {
    @apply flex items-center;
}

label.form-radio-label {
    @apply flex items-center cursor-pointer m-0;
    user-select: none;
}

.form-radio-wrapper .form-radio {
    margin-right: 0.5rem;
}

.form-radio-wrapper .form-radio input[type='radio'] {
    @apply mr-2;
}

.form-radio-wrapper .form-radio input[type='radio']:disabled {
    @apply cursor-not-allowed;
}

.form-radio-wrapper .has-error label,
.form-radio-wrapper .has-error .form-radio {
    @apply text-red-300;
}

.form-radio-wrapper .form-radio-errors {
    @apply text-red-300 ml-0 inline-block font-bold text-sm transition-all mt-1 mb-0 mt-2;
}

.form-radio-wrapper .required {
    @apply text-blue-300;
}

.tooltip {
    @apply ml-2 font-normal;
}
</style>
