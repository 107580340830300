<script>
    export default {
        name: 'CcsTermsFrenchView',
    };
</script>

<template>
    <div>
        <h3 class='cb-service-main-title'>
            Description du service
        </h3>

        <p>Grâce au service CARO, vous pouvez recevoir directement les relevés de cartes de crédit professionnelles de vos clients, pour autant que le titulaire du compte et le titulaire de la carte aient donné leur autorisation. Les fichiers CARO seront livrés mensuellement, conformément à la période comptable de l’émetteur de carte de crédit, pour autant qu’il y ait au moins un paiement effectué avec la carte de crédit au cours de la période comptable de l’émetteur de carte de crédit. Si aucun paiement n’a été effectué au cours de la période comptable, l’émetteur de la carte de crédit n’émettra pas de relevé de dépenses et, par conséquent, CodaBox ne recevra pas ou ne sera pas en mesure de livrer les relevés de dépenses.</p>

        <p>Les fichiers CARO seront transmis par les canaux de livraison sécurisés existants de CODA.</p>

        Les fichiers CARO de CodaBox:

        <ul>
            <li>
                Concernent les cartes de crédit MasterCard et VISA émis par les banques répertoriées sur : <a href='https://www.codabox.com/fr/caro/' target='_blank'>https://www.codabox.com/fr/caro/</a>
            </li>
            <li>Reçoivent un classement logique</li>
            <li>Sont livrés dans un fichier PDF et dans un format structuré</li>
        </ul>

        <p>L'utilisation du service CARO offre à vous et à vos clients de nombreux avantages : plus d'efficacité, moins d'erreurs, moins de documents manquants. Vous avez l'assurance de recevoir tous les relevés de cartes de crédit pertinents (de votre client) et vous n'avez plus besoin d'effectuer d’encodage manuel.</p>

        <p>CodaBox a lancé le service CARO avec une sélection limitée des banques Belges. Le nombre de banques compatibles est progressivement élargi. La liste des banques pour lesquelles le produit CARO est disponible pour les cartes de crédit est disponible sur <a href='https://www.codabox.com/fr/caro/' target='_blank'>https://www.codabox.com/fr/caro/</a></p>

        <p>Le service CARO de CodaBox n'est pas disponible séparément. Les fichiers CARO ne peuvent être fournis que pour les clients professionnels pour lesquels CodaBox fournit également CODA sur la base d'un mandat délivré à cet effet.</p>

        <h3>Tarifs</h3>

        <h4 class='cb-service-title'>
            Frais administratifs
        </h4>

        <p>24 € par référence client (*)</p>

        <h4 class='cb-service-title'>
            Frais récurrents
        </h4>

        <table class='table'>
            <tbody>
                <tr>
                    <td>+ 500 références client</td>
                    <td>3,62 €/mois/référence client</td>
                </tr>
                <tr>
                    <td>Entre 241 et 500 références client</td>
                    <td>4,53 €/mois/référence client</td>
                </tr>
                <tr>
                    <td>Entre 121 et 240 références client</td>
                    <td>5,01 €/mois/référence client</td>
                </tr>
                <tr>
                    <td>Entre 61 et 120 références client</td>
                    <td>6,28 €/mois/référence client</td>
                </tr>
                <tr>
                    <td>Entre 3 et 60 références client</td>
                    <td>7,53 €/mois/référence client</td>
                </tr>
                <tr>
                    <td>Entre 1 et 2 références client</td>
                    <td>9,37 €/mois/référence client</td>
                </tr>
            </tbody>
        </table>

        <p>Pour l’établissement du tarif correct, il est également tenu compte du nombre de services activés pour CODA et VOILA. Pour plus de détails veuillez consulter les Conditions Générales, via le lien ci-dessous.</p>

        <p class='note'>
            <span class='fa fa-info-circle'></span>
            CodaBox ne peut pas déterminer quand le service CODA, CARO ou VOILA n'est plus nécessaire pour un certain client, une carte de crédit ou un compte bancaire, par exemple lorsqu'il n'y a plus de transactions sur un compte ou le compte est clôturé. Le service reste actif (et fait partie du calcul des frais récurrents) jusqu'à ce que vous le
            <a href='https://faq.codabox.com/fr/support/solutions/articles/75000087558-comment-d%C3%A9sactiver-un-service-coda-soda-voila-caro-' target='_blank'>désactiviez vous-même via MyCodaBox</a>.
        </p>

        <p>(*) identification des relevés de cartes de crédit, certains produits bancaires permettent plusieurs cartes sur 1 relevé de cartes de crédit (c.-à-d. référence client).</p>

        <h3>Conditions Générales</h3>

        <p>
            <a href='/static/pdfs/MyCodaBox.com 202401 CB-Fidu FR-Annex CARO (B4-C4-D).pdf' target='_blank'>Conditions générales du service</a>
        </p>
    </div>
</template>

<style>
    h3.cb-service-main-title {
        margin-top: 10px;
    }
    h4.cb-service-title {
        text-decoration: underline;
    }
    .cb-service-info-highlight {
        font-style: italic;
        font-weight: bold;
    }
</style>
