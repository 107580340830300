<script>
    import ListView from '../components/ListView.js';
    import Exporter from '../components/Exporter.vue';
    import BankAccount from '../components/BankAccount.vue';
    import CodaState from '../components/CodaState.vue';
    import ClientLink from '../components/ClientLink.vue';
    import ContentBox from '../components/ContentBox.vue';
    import CustomTitle from '@/components/Title';
    import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
    import { dateFormat } from '@/filters';
    import SearchInput from '@/components/SearchInput';
    import Dropdown from '@/components/DropdownV2';
    import DropdownItem from '@/components/DropdownItem';

    export default {
        name: 'CodaSearch',
        mixins: [ListView],
        props: {
            accountant: {type: Object},
            currentEnvironment: {
                type: Object,
            },
        },
        components: {
            Exporter,
            BankAccount,
            CodaState,
            ClientLink,
            ContentBox,
            CustomTitle,
            FriendlyButton,
            SearchInput,
            Dropdown,
            DropdownItem,
        },
        data () {
            var data = this.listViewData({
                apiModel: 'bff/api/search/fiduciary/coda-mandates',
                defaultSortKey: 'client__client_code',
                archival: {
                    showWarning: false,
                    confirm: { confirmed: false },
                    mandateId: null,
                },
                defaultFilters: {'client__state': 'active'},
            });

            let options = [
                {
                    value: 'allstates',
                    label: 'opt-mandate-all',
                    filter: null,
                }, {
                    value: 'pre-treatment',
                    label: 'lbl-coda-pre-treatment',
                    filter: {'state': 'pre-treatment'},
                }, {
                    value: 'prepared',
                    label: 'lbl-coda-prepared',
                    filter: {'state': 'prepared'},
                }, {
                    value: 'signed',
                    label: 'lbl-coda-signed',
                    filter: {'state': 'signed'},
                }, {
                    value: 'bank-procedure',
                    label: 'lbl-coda-bank-procedure',
                    filter: {'state': 'bank-procedure'},
                }, {
                    value: 'active',
                    label: 'lbl-coda-active',
                    filter: {'state': 'active'},
                }, {
                    value: 'archived',
                    label: 'lbl-coda-archived',
                    filter: {'state': 'archived'},
                }, {
                    value: 'problem',
                    label: 'lbl-coda-problem',
                    filter: {'state': 'problem'},
                },
            ];
            if (this.accountant.delegatesMandateDelivery) {
                options.push({
                    value: 'sent-client',
                    label: 'lbl-coda-sent-client',
                    filter: {'state': 'sent-client'},
                });
            } else {
                options.push({
                    value: 'available-online',
                    label: 'lbl-coda-awaiting-signature',
                    filter: {'state': 'available-online'},
                });
            }
            this.configureSelectFilter(data, 'state', options);

            var _banks = [{
                value: 'allbanks',
                label: 'opt-banks-all',
                filter: null,
            }];

            for (var bank of this.$store.state.allBanks) {
                if (bank.isSupported) {
                    _banks.push({
                        value: bank.id,
                        label: bank.name,
                        filter: {'bank__bank_id': bank.id},
                    });
                }
            }

            this.configureSelectFilter(data, 'banks', _banks);

            return data;
        },
        watch: {
            'currentEnvironment.id': function () {
                this.search();
            },
        },
        mounted () {
            this.watchFilters();
            this.search();
        },
        methods: {
            dateFormat: dateFormat,
            dynamicSearchParams () {
                return { 'fiduciaryIds': [this.currentEnvironment.id] };
            },
        },
    };
</script>

<template>
    <div>
        <div class='cb-fidu-title'>
            <div class='cb-fidu-actions flex'>
                <Exporter model='coda-mandates' :label='$t("btn-export-codas")' class='mr-3' :fiduciary-id='currentEnvironment.id' />
                <Exporter model='bank-accounts' :label='$t("btn-export-accounts")' :fiduciary-id='currentEnvironment.id' />
            </div>
            <CustomTitle class='mt-6 mb-6'>
                {{ $t('h-coda-mandates') }}
            </CustomTitle>
        </div>

        <content-box>
            <div @submit.prevent.stop class='flex'>
                <SearchInput v-model='list.textsearch' class='mr-3' />
                <Dropdown
                    v-model='list.filters.state.selected'
                    class='mr-3'
                    default-value='allstates'
                    direction='right'
                >
                    <DropdownItem
                        v-for='option in list.filters.state.options'
                        :id='option.value'
                        :name='$t(option.label)'
                        :key='option.value'
                        class='whitespace-nowrap'
                    >
                        {{ $t(option.label) }}
                    </DropdownItem>
                </Dropdown>

                <Dropdown
                    v-model='list.filters.banks.selected'
                    default-value='allbanks'
                    direction='right'
                >
                    <DropdownItem
                        v-for='option in list.filters.banks.options'
                        :id='option.value'
                        :name='$t(option.label)'
                        :key='option.value'
                        class='whitespace-nowrap'
                    >
                        {{ $t(option.label) }}
                    </DropdownItem>
                </Dropdown>
            </div>
            <pagination
                :list='list'
                :pagination='pagination'
                :ctrls='getControls()'
                size='small'
                class='my-6'
            />

            <div class='cb-bordered' :class='{"table-responsive": $store.state.gui === "mobile"}'>
                <table class='table cb-searchresults'>
                    <colgroup>
                        <collumn class='cb-col-lg' :sorting='sorting' skey='client__client_code' />
                        <collumn :sorting='sorting' skey='client__name' />
                        <col>
                        <collumn :sorting='sorting' skey='bank__name' />
                        <collumn :sorting='sorting' skey='state_modifed_at' />
                        <collumn :sorting='sorting' skey='state' />
                        <col>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>
                                <span>{{ $t('th-client-code') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='client__client_code' />
                            </th>
                            <th>
                                <span>{{ $t('th-client-name') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='client__name' />
                            </th>
                            <th class='cb-col-lg'>
                                <span>{{ $t('th-accounts') }}</span>
                            </th>
                            <th>
                                <span>{{ $t('th-bank') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='bank__name' />
                            </th>
                            <th>
                                <span>{{ $t('th-state-date') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='state_modified_at' />
                            </th>
                            <th>
                                <span>{{ $t('th-state') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='state' />
                            </th>
                            <th>
                                <span>{{ $t('th-actions') }}</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for='(record, index) in list.records' :key='index'>
                            <td>
                                <ClientLink :client='record.client' document='coda' />
                            </td>
                            <td>{{ record.client.name }}</td>
                            <td>
                                <BankAccount v-for='ba in record.bank_accounts' :key='ba.iban' :bank-account='ba' />
                            </td>
                            <td>{{ record.bank.name }}</td>
                            <td>{{ dateFormat(record.state_modified_at) }}</td>
                            <td>
                                <CodaState :mandate='record' />
                            </td>
                            <td class='flex'>
                                <FriendlyButton
                                    label='btn-resend-coda'
                                    :action='() => {
                                        $router.push({
                                            name:"fidu-client-uid-coda",
                                            query: {
                                                openResendCodaPopup: true
                                            },
                                            params: {
                                                uid: record.client.id
                                            }
                                        })
                                    }'
                                    square
                                    extra-small
                                    secondary
                                    :disabled='!record.bank_accounts.some((ba) => ba.state === "active")'
                                    micro
                                    class='mr-2'
                                    no-margin
                                />
                                <FriendlyButton
                                    label='btn-manage'
                                    :action='() => {
                                        $router.push({
                                            name:"fidu-client-uid-coda",
                                            query: {
                                                highlightedCodaMandateId: record.id
                                            },
                                            params: {
                                                uid: record.client.id
                                            }
                                        })
                                    }'
                                    square
                                    extra-small
                                    micro
                                    no-margin
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <listloader :list='list' />

            <div class='text-center' v-if='!list.loading'>
                <pagination
                    :list='list'
                    :pagination='pagination'
                    :ctrls='getControls()'
                    scroll='scrollTop'
                    no-margin
                />
            </div>
        </content-box>
    </div>
</template>

<style lang="scss" scoped>
.no-row-content {
    height: 18px;
    display: flex;
    align-items: center;

    &:after {
        content: ' ';
        display: block;
        width: 15px;
        height: 1px;
        background: lighten($color-grey-medium, 20%);
    }
}
</style>
