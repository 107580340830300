<script setup>
import { useRouter, useRoute } from 'vue-router';
import { driver } from 'driver.js';
import { useStore } from 'vuex';
import { t } from '@/i18n';
import { watch } from 'vue';
import { datadogRum } from '@datadog/browser-rum';

const props = defineProps({
    orgType: String,
});
// This is the main and only tutorial component.
// It contains two main categories; The Onboarding tutotial, and the Highlights
// We show to a user one or the other(s). If you did not see the Onboarding, we will show you around.
// If you seen it, it means you know the app, let's just show you the new features.
// The Highlights should be, like our Prismic Popup, there to support a new release.
// Plan a removal after x time.

const store = useStore();
const router = useRouter();
const route = useRoute();

// Keep tutorial name as short as possible, don't waste characters on "tutorial"
const ONBOARDING_TUTORIAL_NAME = 'initial';

// Ensure the available tutorials are set before creating any tutorials
store.commit('tutorials/setTutorialsAvailable', { tutorialsAvailable: [
    ONBOARDING_TUTORIAL_NAME,
] });

// global object to store the driverjs instances
// structure: { routeName: { tutorialId, driverInstance } }
const tutorialByRouteName = {};
let currentTutorialRouteName = '';
let currentTutorialStep = 1;
let currentTutorialStartTime = 0;

await store.dispatch('tutorials/loadUserTutorials');

if (store.state.flags['notutorial']) {
    // skipp
} else if (!store.getters['tutorials/hasSeenTutorial'](ONBOARDING_TUTORIAL_NAME)) {
    // this is an onboarding case
    if (
        props.orgType === 'accountant' ||
        props.orgType === 'directCustomer'
    ) {
        // we first redirect users to their homepage
        router.push('/');

        // we construct the onboarding tutorial
        createTutorial({
            id: ONBOARDING_TUTORIAL_NAME,
            routeNameTrigger: 'dashboard',
            steps: [
                {
                    popover: {
                        title: t('initial-onboarding-step-1-ttl', { name: store.state.user.firstName }),
                        description: t('initial-onboarding-step-1-desc'),
                        showButtons: ['next', 'close'],
                        nextBtnText: t('tutorial-btn-start'),
                    },
                },                {
                    element: '.sidenav-companies',
                    popover: {
                        description: t('initial-onboarding-step-2-desc'),
                        side: 'right',
                        align: 'center',
                    },
                },
                {
                    element: '.sidenav-products',
                    popover: {
                        description: t('initial-onboarding-step-3-desc'),
                        side: 'right',
                        align: 'center',
                    },
                },
                {
                    element: '.sidenav-info',
                    popover: {
                        description: t('initial-onboarding-step-4-desc'),
                        side: 'right',
                        align: 'center',
                    },
                },
                {
                    element: '.organization-administration',
                    popover: {
                        description: t('initial-onboarding-step-5-desc'),
                        side: 'bottom',
                        align: 'center',
                        skipMissingElement: true,
                    },
                },
                {
                    element: '.user-menu',
                    popover: {
                        description: t('initial-onboarding-step-6-desc'),
                        side: 'bottom',
                        align: 'end',
                    },
                },
                {
                    element: '.language-switch',
                    popover: {
                        description: t('initial-onboarding-step-7-desc'),
                        side: 'bottom',
                        align: 'end',
                    },
                },
                {
                    popover: {
                        title: t(`initial-onboarding-step-8-ttl`),
                        description: t(`initial-onboarding-step-8-desc`),
                        nextBtnText: t('tutorial-btn-terminate'),
                    },
                },
            ]});
    }
} else {
    // These are the highlights
    // here is an example on how you can use the helper function
    // if you want more details on what you can set in your steps, please refer to https://driverjs.com/docs

    // createTutorial({
    //     id: 'tutorial-id',
    //     routeNameTrigger: 'fidu-coda-search',
    //     steps: [
    //         {
    //             popover: {
    //                 title: t('whatever-title'),
    //                 description: t('whatever-desc'),
    //                 showButtons: ['next', 'close'],
    //             },
    //         },
    //     ],
    // });
}
function eventStartTutorial () {
    datadogRum.addAction('tutorial_started', {
        tutorialId: tutorialByRouteName[currentTutorialRouteName].tutorialId,
        userEmail: store.state.user.email,
        orgType: props.orgType,
    });
}

function eventTutorialClosed () {
    datadogRum.addAction('tutorial_closed', {
        tutorialId: tutorialByRouteName[currentTutorialRouteName].tutorialId,
        userEmail: store.state.user.email,
        orgType: props.orgType,
        activeStep: currentTutorialStep,
        totalSteps: tutorialByRouteName[currentTutorialRouteName].driverInstance.getConfig().steps.length,
    });
}

function eventTutorialCompleted () {
    datadogRum.addAction('tutorial_completed', {
        tutorialId: tutorialByRouteName[currentTutorialRouteName].tutorialId,
        userEmail: store.state.user.email,
        orgType: props.orgType,
        timeElapsed: (performance.now() - currentTutorialStartTime) / 1000,
    });
}

// createTutorial is the driver.js helper function
// todo: add the possibility to change route between steps
function createTutorial ({
    id, // mandatory, defines what key will be stored on cognito user's attributes
    routeNameTrigger = '*', // mandatory, defines where the tutorial will run (one tutorial by route name only)
    steps, // mandatory, all the tutorial steps, passed on to driver.js
    skipable = true, // programmatically adds a skip button on first step
}) {
    // these are the default buttons, this can be overidden on a step-by-step basis
    let showButtons = ['previous', 'next', 'close'];
    let skipButton;

    if (skipable) {
        skipButton = document.createElement('button');
        skipButton.innerText = t('tutorial-btn-skip');
    }

    // handle click checks if the next step exists on the dom if skipMissingElement
    // and skip that step if otherwise
    // todo: also check this on driverjs execution and not only click events
    // todo: also deal with async events (pages changes and async calls)
    function handleClick (direction, state, config) {
        const newIndex = state.activeIndex + direction;
        const stepExists = config.steps[newIndex];
        const skipMissingElement = stepExists && config.steps[newIndex].popover.skipMissingElement;
        const elementExists = document.querySelector(config.steps[newIndex]?.element);
        if (skipMissingElement && !elementExists) {
            tutorialByRouteName[currentTutorialRouteName].driverInstance.moveTo(state.activeIndex + 2 * direction);
        } else {
            tutorialByRouteName[currentTutorialRouteName].driverInstance.moveTo(newIndex);
        }
        if (!isNaN(tutorialByRouteName[currentTutorialRouteName].driverInstance.getActiveIndex())) {
            currentTutorialStep = tutorialByRouteName[currentTutorialRouteName].driverInstance.getActiveIndex() + 1;
        }
    }

    const driverObj = driver({
        steps,
        showButtons,
        nextBtnText: t('tutorial-btn-next'),
        prevBtnText: t('tutorial-btn-previous'),
        doneBtnText: t('tutorial-btn-terminate'),
        onPopoverRender: (el) => {
            if (skipable) {
                el.footerButtons.prepend(skipButton);
            }
        },
        onNextClick: (_, __, { state, config }) => {
            skipButton.style.display = 'none';
            handleClick(1, state, config);
        },
        onPrevClick: (_, __, { state, config }) => {
            if (driverObj.isFirstStep) {
                skipButton.style.display = 'inline';
            }
            handleClick(-1, state, config);
        },
        onDestroyStarted: () => {
            tutorialByRouteName[currentTutorialRouteName].driverInstance.destroy();
        },
        onDestroyed: async (element, step, opts) => {
            store.commit('tutorials/dismissTutorial', { tutorialId: id });
            // Sync with cognito
            await store.dispatch('tutorials/saveUserTutorials');
            // Save to datadog
            if (tutorialByRouteName[currentTutorialRouteName].driverInstance.getConfig().steps.length === currentTutorialStep) {
                eventTutorialCompleted();
            } else {
                eventTutorialClosed();
            }
        },
    });

    skipButton.addEventListener('click', () => {
        tutorialByRouteName[currentTutorialRouteName].driverInstance.destroy();
    });

    // set the route name trigger on a global object to run driver on specific routes
    // we use the route names as keys as a limitation to ensure, for now, to have only one tutorial per page on any time
    tutorialByRouteName[routeNameTrigger] = {
        tutorialId: id,
        driverInstance: driverObj,
    };

    // if the route name trigger is the current route name,
    // or if the route name trigger is anywhere,
    // run immediately
    if (
        route.name === routeNameTrigger ||
        routeNameTrigger === '*'
    ) {
        runTutorial(routeNameTrigger === '*' ? '*' : route.name);
    }
}

function runTutorial (routeName) {
    // Prevent starting multiple tutorials
    if (store.getters['tutorials/tutorialInProgress']) return;
    // Check if this tutorial has been viewed in the current session
    const tutorialId = tutorialByRouteName[routeName].tutorialId;
    if (store.getters['tutorials/hasSeenTutorial'](tutorialId)) return;
    // Start tutorial
    store.commit('tutorials/setTutorialInProgress');
    currentTutorialRouteName = routeName;
    currentTutorialStep = 1;
    currentTutorialStartTime = performance.now();
    // Track
    eventStartTutorial();
    tutorialByRouteName[routeName].driverInstance.drive();
}

// watch routes changes events to trigger specific tutorials
watch(route, ({ name }) => {
    if (name in tutorialByRouteName) {
        runTutorial(name);
    }
});
</script>

<template><template></template></template>
