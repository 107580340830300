<template>
    <div id='main' class='page-host'>
        <router-view />
    </div>
    <UserSettingsPopup />
</template>

<script>
import store from './store';
import UserSettingsPopup from '@/user/UserSettingsPopup';

export default {
    name: 'MainApp',
    components: {
        UserSettingsPopup,
    },
    watch: {
        '$store.state.maintenance': function () {
            store.dispatch('logout');
        },
    },
    async mounted () {
        this.onResize();
        window.addEventListener('resize', this.onResize);
    },
    beforeUnmount () {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        onResize () {
            let w = window.innerWidth;
            if (w <= 640) {
                store.commit('setGui', 'mobile');
            } else if (w < 1200) {
                store.commit('setGui', 'desktop');
            } else {
                store.commit('setGui', 'widescreen');
            }
        },
    },
};
</script>

<style lang='scss'>
@import './app.scss';
</style>

<style lang='postcss'>
/* shitty boostrap overrides */
body {
    @apply bg-grey-30;
}

.pagination > li > a,
.pagination > li > span {
    @apply text-blue-400;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
    @apply bg-blue-400 border-blue-400;
}

input[type=checkbox], input[type=radio] {
    margin: 0;
}

/* Driver.js */
/* buttons are mode too look like friendlyButtons */
.driver-active .driver-overlay, .driver-active * {
    pointer-events: none
}

.driver-active .driver-active-element, .driver-active .driver-active-element *, .driver-popover, .driver-popover * {
    pointer-events: auto
}

@keyframes animate-fade-in {
    0% {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

.driver-fade .driver-overlay {
    animation: animate-fade-in .2s ease-in-out
}

.driver-fade .driver-popover {
    animation: animate-fade-in .2s
}

.driver-popover {
    @apply text-grey-700 rounded-md shadow-md bg-white;
    box-sizing: border-box;
    margin: 0;
    padding: 15px;
    min-width: 250px;
    max-width: 300px;
    z-index: 1000000000;
    position: fixed;
    top: 0;
    right: 0;
}

.driver-popover-title {
    @apply text-2xl;
    font-weight: 700;
    display: block;
    position: relative;
    line-height: 1.5;
    zoom: 1;
    margin: 0
}

.driver-popover-close-btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    color: #d2d2d2;
    z-index: 1;
    text-align: center;
    transition: color;
    transition-duration: .2s;
    background-color: transparent;
    border: none;
}

.driver-popover-close-btn:hover, .driver-popover-close-btn:focus {
    @apply text-blue-400;
    outline: none;
}

.driver-popover-title[style*=block] + .driver-popover-description {
    margin-top: 5px;
}

.driver-popover-description {
    margin-bottom: 0;
    margin-right: 10px;
    font: 14px/normal sans-serif;
    line-height: 1.5;
    font-weight: 400;
    zoom: 1
}

.driver-popover-footer {
    margin-top: 15px;
    text-align: right;
    zoom: 1;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.driver-popover-progress-text {
    font-size: 13px;
    font-weight: 400;
    color: #727272;
    zoom: 1
}

.driver-popover-footer button {
    @apply bg-blue-400 rounded-md text-base transform transition-all text-white text-sm;
    display: inline-block;
    box-sizing: border-box;
    padding: 0 20px;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    line-height: 1.3;
    height: 34px;
    border: none;
}

.driver-popover-footer .driver-popover-btn-disabled {
    opacity: .5;
    pointer-events: none
}

:not(body):has(>.driver-active-element) {
    overflow: hidden !important
}

.driver-no-interaction, .driver-no-interaction * {
    pointer-events: none !important
}

.driver-popover-footer button:hover, .driver-popover-footer button:focus {
    @apply shadow-md;
    transform: translateY(-2px);
}

.driver-popover-navigation-btns {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end
}

.driver-popover-navigation-btns button + button {
    margin-left: 4px
}

.driver-popover-arrow {
    content: "";
    position: absolute;
    border: 5px solid #fff
}

.driver-popover-arrow-side-over {
    display: none
}

.driver-popover-arrow-side-left {
    left: 100%;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-top-color: transparent
}

.driver-popover-arrow-side-right {
    right: 100%;
    border-left-color: transparent;
    border-bottom-color: transparent;
    border-top-color: transparent
}

.driver-popover-arrow-side-top {
    top: 100%;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent
}

.driver-popover-arrow-side-bottom {
    bottom: 100%;
    border-left-color: transparent;
    border-top-color: transparent;
    border-right-color: transparent
}

.driver-popover-arrow-side-center {
    display: none
}

.driver-popover-arrow-side-left.driver-popover-arrow-align-start, .driver-popover-arrow-side-right.driver-popover-arrow-align-start {
    top: 15px
}

.driver-popover-arrow-side-top.driver-popover-arrow-align-start, .driver-popover-arrow-side-bottom.driver-popover-arrow-align-start {
    left: 15px
}

.driver-popover-arrow-align-end.driver-popover-arrow-side-left, .driver-popover-arrow-align-end.driver-popover-arrow-side-right {
    bottom: 15px
}

.driver-popover-arrow-side-top.driver-popover-arrow-align-end, .driver-popover-arrow-side-bottom.driver-popover-arrow-align-end {
    right: 15px
}

.driver-popover-arrow-side-left.driver-popover-arrow-align-center, .driver-popover-arrow-side-right.driver-popover-arrow-align-center {
    top: 50%;
    margin-top: -5px
}

.driver-popover-arrow-side-top.driver-popover-arrow-align-center, .driver-popover-arrow-side-bottom.driver-popover-arrow-align-center {
    left: 50%;
    margin-left: -5px
}

.driver-popover-arrow-none {
    display: none
}
</style>
