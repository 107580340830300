<script>
    export default {
        name: 'SodaTermsEn',
    };
</script>

<template>
    <div>
        <h3 class='cb-service-main-title'>
            Service Description
        </h3>

        <p>Thanks to CodaBox's SODA service, you can automatically receive files generated by social secretariats relative to the calculation of payslips. The SODA files are imported into your accounting program as miscellaneous operations. This service therefore allows you to automate the receipt and encryption of accounting documents that your clients receive in the form of a paper copy from their social secretariat. In order to obtain the SODA files, your client must have at least one signed CODA mandate.</p>

        <p>Please note: the SODA files are intended for accounting breakdowns and therefore do not include the billing information between the social secretariat and the client.</p>

        <p>Once your order is received, the SODA mandate will have either a ‘Not Signed’ or ‘Signed’ status, according to the status of the relevant CODA mandate. As soon as we have sent your order to the social secretariat, the mandate will be ‘Requested’ until we receive the first file. Then, the mandate will be ‘Active’. It is therefore possible for a relatively long delay to occur between the issuing of your order and the activation of the mandate.</p>

        <p>
            <a href='https://www.codabox.com/fr/services/soda/secretariats-sociaux-partenaires/' target='_blank'>List of member social secretariats</a>
        </p>

        <p>If you have clients managed by Securex, you can request SODA files, as for the other social secretariats. A Securex representative will contact you for you to sign a SODA file delivery contract for the relevant social secretariat.</p>

        <p>
            <a href='https://www.codabox.com/fr/services/soda/softwares-prets-pour-lintegration/' target='_blank'>List of compatible accounting software programs</a>
        </p>

        <h3>Pricing</h3>

        <h4 class='cb-service-title'>
            Administrative costs
        </h4>

        <p>When a SODA mandate is activated, upon first receipt of a file for this mandate, you will be billed a one-time fee of 6,00 € per SODA mandate.</p>

        <h4 class='cb-service-title'>
            Recurrent costs
        </h4>

        <p>Once the SODA service is activated for your trustee you will be billed monthly a fee of 67,00 €, irrespective of the number of SODA mandates requested.</p>

        <h3>General Terms and Conditions</h3>

        <p>
            Terms and conditions of the service:
            <a href='/static/pdfs/MyCodaBox.com 202401 CB-Fidu FR-Annex SODA (B2-C2-D).pdf' target='_blank'>FR </a>
            <a href='/static/pdfs/MyCodaBox.com 202401 CB-Fidu NL-Annex SODA (B2-C2-D).pdf' target='_blank'>NL</a>
        </p>
    </div>
</template>

<style>
    h3.cb-service-main-title {
        margin-top: 10px;
    }

    h4.cb-service-title {
        text-decoration: underline;
    }
</style>
