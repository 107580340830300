<script>
    export default {
        name: 'PurchaseTermsEn',
    };
</script>

<template>
    <div>
        <h3 class='cb-service-main-title'>
            Service Description
        </h3>

        <p>With the VOILA service you can receive your clients' purchase invoices from different channels (Zoomit, Peppol, ...) in electronic format. This way, your clients no longer have to send you these purchase invoices.</p>

        <p>You can activate the VOILA service via the menu ‘Mandates & Services > VOILA' in MyCodaBox. This can be done for all your clients at once or for each client separately and requires only a few clicks!</p>        <p>Go to the section 'CLIENT MANDATES & SERVICES - VOILA' to order the service for all your clients at once or one by one.</p>

        <p>IMPORTANT: Inform your clients they will be contacted by CodaBox to accept the VOILA service. This is a crucial step in order to increase the activation rate of the service.</p>

        <p>How does this service work for your clients?</p>
        <ol>
            <li>
                When you order the service for your clients, they will receive an invitation via email to accept the VOILA service.
            </li>
            <li>
                Your clients are informed by CodaBox about the next steps after accepting the mandate and what this means for them.
            </li>
            <li>
                Once a client has accepted the service, CodaBox will use this confirmation to register your client on the various electronic invoicing networks.
            </li>
            <li>
                Your client will no longer have to deliver invoices received via these channels to you. As soon as a supplier sends an invoice, CodaBox will deliver this invoice to your client through the chosen delivery method (via your client portal or via email). You have immediate access to the electronic invoices that CodaBox delivers to you through the same means used to receive CODA.
            </li>
        </ol>

        <h3>Pricing</h3>

        <h4 class='cb-service-title'>
            Administrative costs
        </h4>

        <p>No administrative costs are charged.</p>

        <h4 class='cb-service-title'>
            Recurrent costs
        </h4>

        <p>Until further notice, CodaBox applies a 65% discount to the standard prices listed. If CodaBox were to reduce or withdraw this discount, CodaBox will inform you at least three months in advance.</p>
        <table class='table'>
            <tbody>
                <tr>
                    <td>+ 500 enterprise numbers</td>
                    <td>3,62 €/month/enterprise number</td>
                </tr>
                <tr>
                    <td>Between 241 and 500 enterprise numbers</td>
                    <td>4,53 €/month/enterprise number</td>
                </tr>
                <tr>
                    <td>Between 121 and 240 enterprise numbers</td>
                    <td>5,01 €/month/enterprise number</td>
                </tr>
                <tr>
                    <td>Between 61 and 120 enterprise numbers</td>
                    <td>6,28 €/month/enterprise number</td>
                </tr>
                <tr>
                    <td>Between 3 and 60 enterprise numbers</td>
                    <td>7,53 €/month/enterprise number</td>
                </tr>
                <tr>
                    <td>For 1 and 2 enterprise numbers</td>
                    <td>9,37 € /month/enterprise number</td>
                </tr>
            </tbody>
        </table>

        <p>To determine the correct rate, the number of activated CODA-services and CARO-services is also taken into account. More details can be found in the General Terms and Conditions, via the links below.</p>

        <p class='note'>
            <span class='fa fa-info-circle'></span>
            CodaBox cannot determine when the CODA, CARO or VOILA service is no longer needed for a particular client,
            credit card or bank account, for example, when there are no more transactions on an account, or the account is closed.
            The service remains active (and continues to be included in the calculation of recurrent costs) until you
            <a href='https://faq.codabox.com/en/support/solutions/articles/75000087558-how-to-deactivate-a-service-coda-soda-voila-caro-' target='_blank'>deactivate it yourself via MyCodaBox</a>.
        </p>

        <h3>General Terms and Conditions</h3>

        <p>
            Terms and conditions of the service:
            <a href='/static/pdfs/MyCodaBox.com 202401 CB-Fidu FR-Annex VOILA (B3-C3-D).pdf' target='_blank'>FR </a>
            <a href='/static/pdfs/MyCodaBox.com 202401 CB-Fidu NL-Annex VOILA (B3-C3-D).pdf' target='_blank'>NL</a>
        </p>
    </div>
</template>

<style>
    .hl { color: rgb(153,204,0); }

    h3.cb-service-main-title {
        margin-top: 10px;
    }

    h4.cb-service-title {
        text-decoration: underline;
    }
</style>
