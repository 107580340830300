<template>
    <li role='presentation' :class='{ active: isActive }' class='relative'>
        <NotificationBadge :value='badgeValue' />
        <router-link :to='to'>
            <slot></slot>
        </router-link>
    </li>
</template>

<script>
import NotificationBadge from '@/components/NotificationBadge';

export default {
    name: 'SecondaryNavPill',
    props: {
        isActive: {
            type: Boolean,
            required: true,
        },
        badgeValue: {
            type: Number,
            default: 0,
        },
        to: {
            type: Object,
            required: true,
        },
    },
    components: {
        NotificationBadge,
    },
};
</script>

<style lang='postcss' scoped>
a {
    @apply text-blue-400 px-3 h-10 flex items-center transition ease-in-out rounded-md;
}

a:hover {
    @apply bg-blue-100 no-underline;
}

.active a {
    @apply bg-blue-400 text-white no-underline;
}
</style>
