/* eslint-disable new-cap */
/* eslint-disable no-unused-vars */
import 'whatwg-fetch';
import { createApolloProvider } from '@vue/apollo-option';
import { ApolloClient, HttpLink, InMemoryCache, from, Observable } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import bus from '@/bus.js';
import authUtils from '../auth/utils';
import { Auth } from '@aws-amplify/auth';

let apolloProvider;

export default {
    init: function () {
        const httpLink = new HttpLink({
            uri: '/bff/graphql',
        });

        const authLink = setContext((request, previousContext) => {
            const accessToken = localStorage.getItem('authToken');
            const idToken = localStorage.getItem('idToken');

            return {
                headers: {
                    ...request.headers,
                    authorization: accessToken ? `Bearer ${accessToken}` : '',
                    'ID-TOKEN': idToken || '',
                },
            };
        });

        const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
            if (networkError && networkError.response.status === 401) {
                return new Observable(async (observer) => {
                    try {
                        const currentSession = await Auth.currentSession();
                        authUtils.setAuthToken(currentSession.accessToken.jwtToken);
                        authUtils.setIdToken(currentSession.idToken.jwtToken);

                        const subscriber = {
                            next: observer.next.bind(observer),
                            error: observer.error.bind(observer),
                            complete: observer.complete.bind(observer),
                        };
                        forward(operation).subscribe(subscriber);
                    } catch (err) {
                        bus.emit('session-expired', null);
                        return null;
                    }
                });
            }
        });

        const cache = new InMemoryCache();

        const apolloClient = new ApolloClient({
            link: from([errorLink, authLink.concat(httpLink)]),
            cache,
            defaultOptions: {
                watchQuery: {
                    fetchPolicy: 'no-cache',
                },
                query: {
                    fetchPolicy: 'no-cache',
                },
            },
        });

        const apolloProvider = createApolloProvider({
            defaultClient: apolloClient,
        });

        return { apolloClient, apolloProvider };
    },
};
