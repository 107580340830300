<script setup>

import { onMounted, ref, watchEffect } from 'vue';
import { gql } from '@apollo/client/core';
import { useQuery, useQueryLoading } from '@vue/apollo-composable';
import ProductStatesChart from '@/fiduciary/modules/ProductStatesChart.vue';
import { useRoute, useRouter } from 'vue-router';
import ContentBox from '@/components/ContentBox.vue';
import ClientLink from '@/components/ClientLink.vue';
import EnterpriseNumber from '@/components/EnterpriseNumber.vue';

const router = useRouter();
const route = useRoute();

const props = defineProps({
    currentEnvironment: {
        type: Object,
        required: true,
    },
});

const products = ['CODA', 'SODA', 'VOILA', 'CARO'];
const series = ref([]);
const allClientIds = ref([]);
let clientSearchResult = ref();
let selectedProduct = '';
let selectedStatus = '';
let listLoading = ref(true);

const { refetch, onResult, onError } = useQuery(
    gql`query GetProductStates($fiduciaryId: String) {
        productStates(fiduciaryId:$fiduciaryId) {
            timestamp
            coda { active {count ids} inactive {count ids} }
            soda { active {count ids} inactive {count ids}}
            voila { active {count ids} inactive {count ids}}
            caro { active {count ids} inactive {count ids}}
        }
    }`,
    {
        fiduciaryId: props.currentEnvironment.id,
    },
);
onResult((result, context) => {
    const res = result.data.productStates;
    series.value = [
    {name: 'active', data: [res.coda.active.count, res.soda.active.count, res.voila.active.count, res.caro.active.count]},
    {name: 'inactive', data: [res.coda.inactive.count, res.soda.inactive.count, res.voila.inactive.count, res.caro.inactive.count]},
    ];
    allClientIds.value = {
        'active':  {'CODA': res.coda.active.ids, 'SODA': res.soda.active.ids, 'VOILA': res.voila.active.ids, 'CARO': res.caro.active.ids},
        'inactive': {'CODA': res.coda.inactive.ids, 'SODA': res.soda.inactive.ids, 'VOILA': res.voila.inactive.ids, 'CARO': res.caro.inactive.ids},
    };
    getClientInfo(selectedProduct, selectedStatus);
});
onError((_, context) => {
    series.value = [];
});
const loading = useQueryLoading();

watchEffect(async () => {
    refetch({
        fiduciaryId: props.currentEnvironment.id,
    });
});

onMounted(async () => {
    await router.isReady();
    selectedProduct = route.query.product;
    selectedStatus = route.query.status;
});

function getClientInfo (product, status) {
    if (!(product || status)) {
        return;
    }
    listLoading.value = true;

    router.replace({query: {'product': product, 'status': status}});
    selectedProduct = product;
    selectedStatus = status;

    const clientIds = allClientIds.value[status][product];
    const { onResult, onError } = useQuery(
        gql`query Clients($input: ClientSearchInput) {
                clients(input: $input) {
            paging {offset limit count}
            results {id clientCode enterpriseNumber enterpriseName }
        }
    }`,
        {
            input: {
                fiduciaryId: props.currentEnvironment.id,
                clientIds: clientIds,
                limit: 1000000,
            },
        },
    );
    onResult((result, context) => {
        clientSearchResult.value = result.data.clients.results;
        listLoading.value = false;
    });
    onError((_, context) => {
        clientSearchResult.value = [];
        listLoading.value = false;
    });
}

function getTitle () {
    if (!selectedProduct || !selectedStatus) {
        return '';
    }
    return `${selectedProduct} - ${selectedStatus}`;
}

</script>

<template>
    <ProductStatesChart
        :products='products'
        :series='series'
        :loading='loading'
        @clicked='getClientInfo'
    />
    <ContentBox :title='getTitle()'>
        <div class='text-center p-10' v-if='listLoading'>
            <i class='fa fa-circle-o-notch fa-spin fa-5x'></i>
        </div>
        <div v-else class='cb-bordered' :class='{"table-responsive": $store.state.gui === "mobile"}'>
            <table class='table cb-searchresults'>
                <colgroup>
                    <collumn class='cb-col-lg' skey='client_code' />
                    <collumn class='cb-col-md' skey='name' />
                    <collumn class='cb-col-md' skey='enterprise_num' />
                    <col class='cb-col-xl'>
                </colgroup>
                <thead>
                    <tr>
                        <th>
                            <span>{{ $t('th-client-code') }}</span>
                        </th>
                        <th>
                            <span>{{ $t('th-client-name') }}</span>
                        </th>
                        <th>
                            <span>{{ $t('th-enterprise-number') }}</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for='record in clientSearchResult' :key='record.id'>
                        <td>
                            <ClientLink :client='{id: record.id, client_code: record.clientCode}' />
                        </td>
                        <td>{{ record.enterpriseName }}</td>
                        <td>
                            <EnterpriseNumber :enterprise-number='record.enterpriseNumber' />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ContentBox>
</template>
