<script setup>
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import DetachBankAccountPopup from './DetachBankAccountPopup.vue';
import {defineProps, ref, watch} from 'vue';
import { useLazyQuery } from '@vue/apollo-composable';
import {gql} from '@apollo/client/core';
import notify from '@/notify';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const emit = defineEmits(['reset']);

const props = defineProps({
    iban: {
        type: String,
        default: '',
    },
    errorCode: {
        type: String,
        default: '',
    },
    idSuffix: {
        type: String,
    },
});
const otherClient = ref();
const showDetachPopup = ref(false);

const {loading, onResult, onError, load, refetch} = useLazyQuery(
    gql`query GetBankAccountOwner($iban: String) {
        bankAccountOwner(iban:$iban) {
            id
            enterpriseName
            clientCode
        }
    }`,
    {
        iban: props.iban,
    },
);
onResult((result, context) => {
    otherClient.value = result.data.bankAccountOwner;
});
onError((_, context) => {
    notify.error(t('err-unknown-retry-later'));
});
const clientIdLoading = loading;

watch(
    () => ([props.iban, props.errorCode]),
    ([iban, error]) => {
        otherClient.value = null;
        if (error === 'err-ibanBankAccountExistsForFidu') {
            load(null, { iban: props.iban }) || refetch({ iban: props.iban });
        }
    },
);

function push () {
    showDetachPopup.value = true;
}

function closeDetachPopup () {
    showDetachPopup.value = false;
    otherClient.value = null;
    load(null, { iban: props.iban }) || refetch({ iban: props.iban });
}

function onDetached () {
    emit('reset');
}

function labelContext () {
    return {
        name: otherClient.value.enterpriseName,
    };
}

</script>

<template>
    <div v-if='props.errorCode && props.errorCode.includes("ibanBankAccountExists")' class='grid box error pt-3 pb-4 mb-3'>
        <div
            class='grid-cols-12'
        >
            <div class='px-3' v-if='props.errorCode === "err-ibanBankAccountExistsForFidu"' :id='`ba-exists-for-fidu-${props.idSuffix}`'>
                <p class='mb-3'>
                    {{ $t('p-err-unique-bankaccount-same') }}
                </p>
                <div v-if='clientIdLoading'>
                    <i class='fa fa-circle-o-notch fa-spin'></i>
                </div>
                <FriendlyButton
                    v-if='otherClient'
                    symbol='chain-broken'
                    @click='push()'
                    id='detachBAButton'
                    extra-small
                    square
                    danger
                    label='btn-detach-bank-account-from'
                    :label-context='labelContext()'
                    no-margin
                    small-icon
                    red
                />
                <DetachBankAccountPopup
                    :show='showDetachPopup'
                    @close='closeDetachPopup'
                    @detached='onDetached'
                    :iban='props.iban'
                    :client='otherClient'
                />
            </div>
            <p v-if='props.errorCode === "err-ibanBankAccountExists"' :id='`ba-exists-${props.idSuffix}`'>
                {{ $t('p-err-unique-bankaccount-other') }}
                {{ $t('p-err-unique-bankaccount-b') }}
                <a href='mailto:helpdesk@codabox.com'>helpdesk@codabox.com</a>.
            </p>
        </div>
    </div>
</template>
