<script>
    export default {
        name: 'WarningComponent',
    };
</script>
<template>
    <div
        class='text-white mb-3 mt-5 px-4 py-2 bg-orange-300 inline-block rounded-md flex items-center'
    >
        <i class='fa fa-exclamation-triangle mr-3'></i>
        <slot name='header'></slot>
        <slot></slot>
    </div>
</template>
