import { datadogRum } from '@datadog/browser-rum';

// datatog rum (analytics) init
datadogRum.init({
    applicationId: 'a29ecf3d-fa31-4a64-9e9d-2fb5ed61edca',
    clientToken: 'pub015a42784d817083c1034d3cbbb64e1d',
    site: 'datadoghq.eu',
    service: 'mycodabox',
    env: location.host.includes('mcbx-cmt') || location.host.includes('localhost') ? 'development' : location.host,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 75,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    beforeSend: event => {
        // remove hash from url
        event.view.url = event.view.url.replace('/#', '');
    },
});
